import { React, Container, Link } from "common";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ Two Wheeler Loan Charges</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Strong network of our branches</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">More than 5 lakh satisfied customers</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Diversified loan options for every need</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="charges">
                            <div className="widthfix_10px">
                                <ul className="aboutIIcol">
                                    <li className="aboutIIcol">
                                        <ul className="aboutLeftNavi">
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesbusiness" >BL &amp; PL Charges</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesbike" className="current">Two Wheeler Loan Charges</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesgold">Gold Loan Charges</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="aboutIIcol">
                                        <ul className="aboutIISubCol">
                                            <li className="aboutIISubCol">
                                                <div className="aboutHeadText">Two Wheeler Loan Charges</div>

                                                <table className="chargesTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Bike Loans </th>
                                                            <th>Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Processing Fee</td>
                                                            <td>Up to 6% of Loan amount<br />*Varies based on the Asset &amp; Scheme opted by the Customer</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ECS / NACH Charge</td>
                                                            <td>Up to Rs 1500/-</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Document Charge</td>
                                                            <td>Up to Rs 1500/-</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Late Payment Charge (LPC)</td>
                                                            <td>2% per month (p.m.) on overdue EMI + applicable taxes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Repayment/EMI Bounce Charges</td>
                                                            <td>Charges in Rs. Loan sanction amount in Rs.<br />
                                                                500 for less than 5 lacs <br />
                                                                1000 for 5 to 50 Lacs<br />
                                                                1500 for 50 Lacs to 2 Cr<br />
                                                                2000 for greater than 2 Cr
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Part Prepayment Charges</td>
                                                            <td>Up to 5% of prepaid amount + applicable taxes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Foreclosure Charges</td>
                                                            <td>5% of principal outstanding + applicable taxes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Legal /Recovery /Repo /Parking & other Charges</td>
                                                            <td>As per actuals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Duplicate NOC Charges</td>
                                                            <td>Rs 250/- + applicable taxes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cash Collection / Visit Charges</td>
                                                            <td>150 per Visit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Field Investigation Charge</td>
                                                            <td>150 per Visit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Loan Restructuring Charges</td>
                                                            <td>0.03</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mandate Registration Charges</td>
                                                            <td>Rs.200/-</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stamp Duty/Stamping/Franking Charges</td>
                                                            <td>As per actuals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Loan Cancellation Charges</td>
                                                            <td>0.5% of loan amount or Rs. 5000, whichever is higher</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                            <li className="aboutIISubCol">
                                                <div className="aboutContactBlk">
                                                    <div className="abtContactIcon"></div>
                                                    <div className="abtContactTxtHead">Talk to Sales</div>
                                                    <div className="abtContactTxt">Want to discuss about a loan? </div>
                                                    <div className="abtContactTxt">Just pick up the phone to chat with a member of our sales team.</div>
                                                    <div className="abtContactPhNo">+91 80 26496555</div>
                                                </div>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
